import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const ParcelasVillarrica = lazy(() => import('./components/ParcelasVillarrica'));
const VogelHaus = lazy(() => import('./components/vogel-haus/VogelHaus'));

function App() {
  return (
    <Router>
      <div className="App">
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<ParcelasVillarrica />} />
            <Route path="/vogelhaus" element={<VogelHaus/>} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;